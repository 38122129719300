<template>
  <validation-observer ref="resetForm" #default="{ invalid }" class="w-100">
    <b-row>
      <b-col cols="6">
        <b-form-group label="Nome" label-for="nome">
          <validation-provider #default="{ errors }" name="Nome" rules="required" vid="nome">
            <b-form-input v-model="value.nome" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!-- <b-col cols="6">
            <b-form-group label="Celular" label-for="telefone">
              <validation-provider #default="{ errors }" name="Celular" rules="required">
                <b-form-input v-model="value.telefone" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
      <b-col cols="6">
        <b-form-group label="Email" label-for="email">
          <validation-provider #default="{ errors }" rules="required|email">
            <b-form-input id="email" v-model="value.email" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!-- <b-col cols="6">
            <b-form-group label="Tipo de usuário" label-for="userType">
              <validation-provider
                #default="{ errors }"
                name="Tipo de usuário"
                vid="userType"
                rules="required"
              >
                <v-select
                  v-model="value.type"
                  :options="userTypes"
                  :clearable="false"
                  :reduce="(option) => option.label"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
      <!-- <b-col cols="12">
        <b-form-group label="Cedente" label-for="assignor">
          <validation-provider #default="{ errors }" name="Cedente" vid="assignor" rules="required">
            <v-select
              v-model="value.cedente_id"
              :options="assignors"
              :clearable="false"
              :reduce="(option) => option.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col> -->
    </b-row>

    <slot name="footer" :invalid="invalid">
      <div class="d-flex flex-row justify-content-center mt-2">
        <b-button
          variant="outline-secondary"
          class="mb-1 mr-1 text-dark font-weight-bolder"
          @click="closeModal"
        >
          Cancelar
        </b-button>
        <b-button
          variant="secondary"
          class="mb-1 bg-light-secondary font-weight-bolder"
          :disabled="invalid"
          @click="saveInvite"
        >
          Enviar convite
        </b-button>
      </div>
    </slot>
  </validation-observer>
</template>

<script>
import { BCol, BRow, BButton, BFormInput, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'InviteForm',
  components: {
    BCol,
    BRow,
    BButton,
    vSelect,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {},
    },
    saveInvite: {
      type: Function,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      invite: {
        nome: '',
        email: ''
      },
      assignors: [],
      userTypes: [
        {
          label: 'Administrador',
          value: 'admin',
        },
        {
          label: 'Parceiro',
          value: 'cedente',
        },
      ],
    }
  },
  async mounted() {
    await this.fetchAssignors()
  },
  methods: {
    async fetchAssignors() {
      const data = await this.$store.dispatch('admin/getAssignors')
      this.assignors = data.map((assignor) => ({
        label: assignor.nome,
        value: assignor.id,
      }))
    },
  },
  watch: {
    invite: {
      handler(value) {
        this.$emit('input', value)
      },
      deep: true,
    },
    value: {
      handler(value) {
        this.invite = value
      },
      deep: true,
    },
  },
}
</script>
