<template>
  <b-modal
    id="editUserModal"
    centered
    hide-footer
    header-bg-variant="white"
    no-body
    no-close-on-backdrop
  >
    <h3 class="mb-50">Editar usuário</h3>

    <div class="d-flex flex-column justify-content-center align-items-start">
      <invite-form v-model="user">
        <template #footer="{ invalid }">
          <div class="d-flex flex-row justify-content-center mt-2">
            <b-button
              variant="outline-secondary"
              class="mb-1 mr-1 text-dark font-weight-bolder"
              @click="$bvModal.hide('editUserModal')"
            >
              Cancelar
            </b-button>
            <b-button
              variant="outline-secondary"
              class="mb-1 mr-1 text-dark font-weight-bolder"
              :disabled="blockLoading"
              @click="deleteUser"
            >
              Excluir
            </b-button>
            <b-button
              variant="secondary"
              class="mb-1 bg-light-secondary font-weight-bolder"
              :disabled="invalid"
              @click="updateUser"
            >
              Salvar informações
            </b-button>
          </div>
        </template>
      </invite-form>
    </div>
  </b-modal>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import InviteForm from '@/views/admin/users/components/InviteForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'EditUserModal',
  components: {
    BButton,
    InviteForm,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      blockLoading: false,
      user: {},
    }
  },
  methods: {
    async updateUser() {
      await this.$store.dispatch('admin/updateUser', this.user)
      this.$bvModal.hide('editUserModal')
      this.$swal.fire({
        title: 'Sucesso!',
        text: 'Usuário salvo com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok',
      })
      this.$emit('invite-updated')
    },
    async deleteUser() {
      this.blockLoading = true
      await this.$store.dispatch('admin/blockUser', [this.user.id])
      this.$bvModal.hide('editUserModal')
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Usuário bloqueado.',
          icon: 'CheckCircleIcon',
          variant: 'dark',
          text: `O usuário ${payload.nome} foi bloqueado com sucesso!`,
        },
      })
      this.blockLoading = false
      this.$emit('invite-updated')
    },
  },
  watch: {
    currentUser: {
      handler() {
        this.$set(this.user, 'id', this.currentUser.id)
        this.$set(this.user, 'nome', this.currentUser.nome)
        this.$set(this.user, 'email', this.currentUser.email)
        this.$set(this.user, 'cedente_id', this.currentUser.cedente_id)
      },
      deep: true,
    },
  },
}
</script>
