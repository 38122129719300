<template>
  <b-modal
    id="editInviteModal"
    centered
    hide-footer
    header-bg-variant="white"
    no-body
    no-close-on-backdrop
  >
    <h3 class="mb-50">Editando convite</h3>

    <div class="d-flex flex-column justify-content-center align-items-start">
      <invite-form v-model="updatedInvite">
        <template #footer="{ invalid }">
          <div class="d-flex flex-row justify-content-center mt-2">
            <b-button
              variant="outline-secondary"
              class="mb-1 mr-1 text-dark font-weight-bolder"
              @click="$bvModal.hide('editInviteModal')"
            >
              Cancelar
            </b-button>
            <b-button
              variant="outline-secondary"
              class="mb-1 mr-1 text-dark font-weight-bolder"
              :disabled="invalid"
              @click="deleteInvite"
            >
              Excluir
            </b-button>
            <b-button
              variant="secondary"
              class="mb-1 bg-light-secondary font-weight-bolder"
              @click="sendInvite"
              :disabled="loading"
            >
              {{ sendInviteLabel }}
            </b-button>
          </div>
        </template>
      </invite-form>
    </div>
  </b-modal>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import InviteForm from '@/views/admin/users/components/InviteForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'EditInviteModal',
  components: {
    BButton,
    InviteForm,
  },
  props: {
    currentInvite: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      updatedInvite: {},
    }
  },
  methods: {
    async deleteInvite() {
      await this.$store.dispatch('admin/deleteInvite', this.updatedInvite.id)
      this.$bvModal.hide('editInviteModal')
      this.$swal.fire({
        title: 'Sucesso!',
        text: 'Convite deletado com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok',
      })
      this.$emit('invite-updated')
    },
    async sendInvite() {
      try {
        this.loading = true
        await this.$store.dispatch('admin/sendInvite', this.updatedInvite.id)
        this.$bvModal.hide('editInviteModal')

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Convite enviado com sucesso!',
            icon: 'CheckCircleIcon',
            variant: 'dark',
            text: `Convite enviado para o email ${this.updatedInvite.email}!`,
          },
        })
        this.$emit('invite-updated')
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    sendInviteLabel() {
      return this.currentInvite.ultimo_envio ? 'Reenviar convite' : 'Enviar convite'
    },
  },
  watch: {
    currentInvite: {
      handler() {
        this.$set(this.updatedInvite, 'id', this.currentInvite.id)
        this.$set(this.updatedInvite, 'nome', this.currentInvite.nome)
        this.$set(this.updatedInvite, 'email', this.currentInvite.email)
        this.$set(this.updatedInvite, 'cedente_id', this.currentInvite.cedente_id)
      },
      deep: true,
    },
  },
}
</script>
